import React, { useState, useEffect } from "react";
import {
  Modal,
  Space,
  Typography,
  Button,
  Dropdown,
  message,
  Table,
  Input,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  FolderOutlined,
  KeyOutlined,
  EyeOutlined,
  CopyOutlined,
  EyeInvisibleOutlined,
  MoreOutlined,
  DownOutlined,
  ShareAltOutlined,
  ExportOutlined,
  SearchOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import CreateSecretModal from "./component/CreateSecretModal";
import CreateFolderModal from "./component/CreateFolderModal";
import EditSecretModal from "./component/EditSecretModal";
import EditFolderModal from "./component/EditFolderModal";
import ShareModal from "../../components/share/ShareModal";
import PasswordUpload from "./component/PasswordUpload";

const { Title, Text } = Typography;

const PasswordManager = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [appName, setAppName] = useState("");
  const [userId, setUserId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isFolderEditModalVisible, setIsFolderEditModalVisible] =
    useState(false);
  const [isFolderModalVisible, setIsFolderModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [editingSecret, setEditingSecret] = useState(null);
  const [editingFolder, setEditingFolder] = useState(null);
  const { appId } = useParams();
  const [folders, setFolders] = useState([]);
  const [secrets, setSecrets] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [showSecretValues, setShowSecretValues] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isImportOpen, setIsImportOpen] = useState(false);

  const showCreateSecretModal = () => setIsModalVisible(true);
  const showCreateFolderModal = () => setIsFolderModalVisible(true);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      fetchAppDetails(storedToken);
      fetchFolders(storedToken);
      fetchSecrets(storedToken);
      try {
        const decodedToken = JSON.parse(atob(storedToken.split(".")[1]));
        const userIdFromToken = decodedToken.id || decodedToken.userId;
        setUserId(userIdFromToken);
      } catch (error) {
        console.error("Token decode edilemedi:", error);
      }
    }
  }, [appId]);

  useEffect(() => {
    setFilteredData([...secrets, ...folders]);
  }, [secrets, folders]);

  const fetchAppDetails = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/apps/${appId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAppName(response.data.name);
    } catch (error) {
      console.error("Error fetching app details:", error);
    }
  };

  const fetchFolders = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/folders/app/${appId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFolders(
        response.data.folders.map((folder) => ({
          ...folder,
          type: "Folder",
          shared: [],
        }))
      );
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  const fetchSecrets = async (token) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/secrets/${appId}/secrets`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setSecrets(
        response.data.secrets.map((secret) => ({
          ...secret,
          type: "Secret",
          shared: [],
        }))
      );
    } catch (error) {
      console.error("Error fetching secrets:", error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filteredApps = [...secrets, ...folders].filter((item) =>
      item.name.toLowerCase().includes(value)
    );
    setFilteredData(filteredApps);
  };

  const toggleSecretVisibility = (id) => {
    setShowSecretValues((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleDeleteFolder = async (folderId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${API_URL}/api/folders/${folderId}/delete`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        fetchFolders(token);
        message.success("Folder deleted successfully");
      } else {
        message.error(response.data.error || "Failed to delete folder");
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      message.error(error.response?.data?.error || "Failed to delete folder");
    }
  };

  const handleDeleteSecret = async (secretId, secretName) => {
    Modal.confirm({
      title: "Are you sure you want to delete this secret?",
      content: (
        <span>
          You are about to delete the secret: <Text strong>{secretName}</Text>.
          This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const token = localStorage.getItem("token");
        const url = `${API_URL}/api/secrets/${secretId}`;

        try {
          const response = await axios.delete(url, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            message.success(`Secret "${secretName}" deleted successfully.`);
            fetchSecrets(token);
          } else {
            message.error(response.data.error || "Failed to delete secret");
          }
        } catch (error) {
          console.error("Error deleting secret:", error);
          message.error(
            error.response?.data?.error || "Failed to delete secret"
          );
        }
      },
    });
  };

  const handleDeleteSelected = () => {
    const selectedSecrets = selectedRowKeys
      .map((id) => filteredData.find((item) => item.id === id))
      .filter((item) => item && item.secret_id);
    const secretNames = selectedSecrets.map((secret) => secret.name).join(", ");

    Modal.confirm({
      title: "Are you sure you want to delete the selected items?",
      content: (
        <span>
          You are about to delete the following secrets:{" "}
          <Text strong>{secretNames}</Text>. This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const token = localStorage.getItem("token");

        try {
          await Promise.all(
            selectedSecrets.map((secret) =>
              axios.delete(`${API_URL}/api/secrets/${secret.secret_id}`, {
                headers: { Authorization: `Bearer ${token}` },
              })
            )
          );

          message.success("Selected items deleted successfully.");
          fetchSecrets(token);
          setSelectedRowKeys([]);
        } catch (error) {
          console.error("Error deleting selected items:", error);
          message.error("Failed to delete selected items.");
        }
      },
    });
  };

  const handleExport = () => {
    const dataToExport = filteredData.map((item) => ({
      Name: item.name,
      Type: item.type,
      Value: item.type === "Secret" ? item.value : "-",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Password Manager");

    const fileName = `${appName.replace(/\s+/g, "-")}_data.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const showImportModal = () => {
    setIsImportOpen(true);
  };

  const handleShareClick = (record) => {
    setSelectedRecord(record);
    setIsShareModalVisible(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          {record.type === "Folder" ? (
            <Link to={`/apps/${appId}/${record.id}`}>
              <FolderOutlined /> <strong>{text}</strong>
            </Link>
          ) : (
            <span>
              <KeyOutlined /> <strong>{text}</strong>
            </span>
          )}
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) =>
        record.type === "Secret" ? (
          <div className="flex justify-between items-center">
            <span>{showSecretValues[record.id] ? text : "••••••••"}</span>
            <Space>
              <Button
                style={{
                  color: selectedRowKeys.includes(record.key)
                    ? "white"
                    : "inherit",
                }}
                icon={
                  showSecretValues[record.id] ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                type="text"
                onClick={() => toggleSecretVisibility(record.id)}
              />
              <Button
                style={{
                  color: selectedRowKeys.includes(record.key)
                    ? "white"
                    : "inherit",
                }}
                icon={<CopyOutlined />}
                type="text"
                onClick={() => navigator.clipboard.writeText(text)}
              />
            </Space>
          </div>
        ) : (
          "-"
        ),
    },
    {
      key: "actions",
      render: (text, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Space
                      onClick={() =>
                        record.type === "Folder"
                          ? (setEditingFolder(record),
                            setIsFolderEditModalVisible(true))
                          : (setEditingSecret(record),
                            setIsEditModalVisible(true))
                      }
                      style={{ width: "100px" }}
                    >
                      <EditOutlined /> Edit
                    </Space>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Space
                      onClick={() => {
                        handleShareClick(record);
                        setIsShareModalVisible(true);
                      }}
                      style={{ width: "100px" }}
                    >
                      <ShareAltOutlined /> Share
                    </Space>
                  ),
                },
                {
                  key: "3",
                  label: (
                    <Space
                      onClick={() =>
                        record.type === "Folder"
                          ? handleDeleteFolder(record.id)
                          : handleDeleteSecret(record.secret_id, record.name)
                      }
                      type="primary"
                      danger
                      style={{
                        width: "100px",
                        color: "red",
                      }}
                    >
                      <DeleteOutlined /> Delete
                    </Space>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button
              type="text"
              style={{
                color: selectedRowKeys.includes(record.key)
                  ? "white"
                  : "inherit",
              }}
            >
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <div style={{ padding: "12px 0" }}>
      <Title level={2}>{appName}</Title>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          {/* Sol taraftaki butonlar */}
          <Col xs={24} sm={12} md={12} lg={12}>
            <Space direction="horizontal" size="middle" wrap>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <Button
                          icon={<PlusOutlined />}
                          onClick={showCreateSecretModal}
                        >
                          Create Secret
                        </Button>
                      ),
                    },
                    {
                      key: "2",
                      label: (
                        <Button
                          icon={<PlusOutlined />}
                          onClick={showCreateFolderModal}
                        >
                          Create Folder
                        </Button>
                      ),
                    },
                  ],
                }}
                placement="bottom"
                arrow
                trigger={["click"]}
              >
                <Button type="primary">
                  <PlusOutlined /> Create
                </Button>
              </Dropdown>

              <Button
                onClick={() => {
                  if (selectedRowKeys.length > 0) {
                    const selectedItem = [...folders, ...secrets].find(
                      (item) => item.id === selectedRowKeys[0]
                    );
                    if (selectedItem) {
                      setSelectedRecord(selectedItem);
                      setIsShareModalVisible(true);
                    }
                  }
                }}
                disabled={selectedRowKeys.length === 0}
                icon={<ShareAltOutlined />}
              >
                Share
              </Button>

              <Dropdown
                disabled={selectedRowKeys.length === 0}
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <Space
                          style={{ color: "red" }}
                          onClick={handleDeleteSelected}
                        >
                          <DeleteOutlined /> Delete
                        </Space>
                      ),
                    },
                  ],
                }}
                placement="bottom"
                arrow
                trigger={["click"]}
              >
                <Button>
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </Col>

          {/* Sağ taraftaki arama ve dropdown */}
          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[8, 8]} align="middle" justify="end" wrap={false}>
              <Col>
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined />}
                  value={searchValue}
                  onChange={handleSearch}
                  style={{ width: "100%", maxWidth: 250 }}
                />
              </Col>
              <Col>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <Space
                            style={{ width: "100px" }}
                            onClick={showImportModal}
                          >
                            <ImportOutlined /> Import
                          </Space>
                        ),
                      },
                      {
                        key: "2",
                        label: (
                          <Space
                            style={{ width: "100px" }}
                            onClick={handleExport}
                          >
                            <ExportOutlined /> Export
                          </Space>
                        ),
                      },
                    ],
                  }}
                  placement="bottom"
                  arrow
                  trigger={["click"]}
                >
                  <Button type="text">
                    <MoreOutlined style={{ fontSize: "20px" }} />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          rowKey={(record) => record.id}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          scroll={{ x: "max-content" }}
        />
      </Space>
      <CreateSecretModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        appId={appId}
        onSecretCreated={() => fetchSecrets(localStorage.getItem("token"))}
      />

      <CreateFolderModal
        isFolderModalVisible={isFolderModalVisible}
        setIsFolderModalVisible={setIsFolderModalVisible}
        appId={appId}
        onFolderCreated={() => fetchFolders(localStorage.getItem("token"))}
      />
      <EditSecretModal
        isEditModalVisible={isEditModalVisible}
        setIsEditModalVisible={setIsEditModalVisible}
        editingSecret={editingSecret}
      />
      <EditFolderModal
        isFolderEditModalVisible={isFolderEditModalVisible}
        setIsFolderEditModalVisible={setIsFolderEditModalVisible}
        editingFolder={editingFolder}
      />
      <PasswordUpload
        isImportOpen={isImportOpen}
        setIsImportOpen={setIsImportOpen}
        name={appName}
      />
      <ShareModal
        visible={isShareModalVisible}
        onCancel={() => setIsShareModalVisible(false)}
        itemId={selectedRecord?.id}
        itemType={selectedRecord?.type.toLowerCase()}
        appId={appId}
        userId={userId}
        token={localStorage.getItem("token")}
      />
    </div>
  );
};

export default PasswordManager;
