import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import styles from "./AuthForm.module.css";
import "./AuthForm.css";
import { Flex, Typography } from "antd";
import axios from "axios";

const { Title } = Typography;

function AuthForm() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isSignup, setIsSignup] = useState(false);
  const [username, setUsername] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  // const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     try {
  //       const response = await axios.get(`${API_URL}/api/users`, {
  //         withCredentials: true,
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       });
  //       setUsers(response.data);
  //       localStorage.setItem("userId", response.data.id);
  //     } catch (error) {
  //       console.error("Error fetching users:", error);
  //     }
  //   };
  //   fetchUsers();
  // }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/api/users/register`,
        {
          name: username,
          email: signupEmail,
          password: signupPassword,
          role: "user",
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      setSuccessMessage("Registration successful!");
      setErrorMessage(null);
      if(response.data.token){
        localStorage.setItem("token", response.data.token)
      }
            
      //setUsers([...users, response.data]);
      navigate("/verify");
    } catch (error) {
      const errorMsg = error.response?.data?.error || "Registration failed.";
      setErrorMessage(errorMsg);
      setSuccessMessage(null);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${API_URL}/api/users/login`,
        { email: loginEmail, password: loginPassword },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            //Accept: "application/json",
          },
        }
      );

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userEmail", loginEmail);
      setSuccessMessage("Login successful!");
      setErrorMessage(null);
      console.log('suucccessseeedd');      
      navigate("/dashboard");
    } catch (error) {
      const errorMsg =
        error.response?.data?.error || "An error occurred. Please try again.";
      setErrorMessage(errorMsg);
      setSuccessMessage(null);
    }
  };

  const handleToggle = () => {
    setIsSignup((prev) => !prev);
  };

  return (
    <div className="globalStyles">
      <div className={`container ${isSignup ? "change" : ""}`}>
        <div className="forms-container">
          <div className={`form-control signup-form`}>
            <form onSubmit={handleRegister}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="/logo3.png"
                  alt="Logo"
                  className="mb-1"
                  width={120}
                  style={{
                    borderRadius: "10px",
                    marginBottom: "4px",
                  }}
                />
              </div>
              <h2>Sign Up</h2>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="email"
                placeholder="Email"
                value={signupEmail}
                onChange={(e) => setSignupEmail(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={signupPassword}
                onChange={(e) => setSignupPassword(e.target.value)}
                required
              />
              <button type="submit">Signup</button>
            </form>
            {errorMessage && (
              <p className="text-red-500 text-center mt-3">{errorMessage}</p>
            )}
            {successMessage && (
              <p className="text-green-500 text-center mt-3">
                {successMessage}
              </p>
            )}
          </div>
          <div className="form-control signin-form">
            <form onSubmit={handleLogin}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="/logo3.png"
                  alt="Logo"
                  width={120}
                  style={{
                    borderRadius: "10px",
                    marginBottom: "4px",
                  }}
                />
              </div>
              <Title level={2}>Log In</Title>
              {errorMessage && (
                <p
                  className="text-red-500 text-center"
                  style={{ margin: "10px 0px 0px" }}
                >
                  {errorMessage}
                </p>
              )}
              <input
                type="text"
                placeholder="Email"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
                required
              />
              <Flex justify={"space-between"}>
                <a
                  href="/Forgot-Password"
                  style={{ fontSize: "12px", color: "black" }}
                >
                  Forgot your password?
                </a>{" "}
                <a
                  href="/emergency-Verify"
                  style={{ color: "red", marginLeft: "10px", fontSize: "12px" }}
                >
                  Emergency access
                </a>
              </Flex>
              <button type="submit">Login</button>
            </form>
            {successMessage && (
              <p className="text-green-500 text-center mt-3">
                {successMessage}
              </p>
            )}
          </div>
        </div>
        <div className="intros-container">
          <div className="intro-control signin-intro">
            <div className="intro-control__inner">
              <Title level={1} className="title-intro">
                Hello, Friend!
              </Title>
              <p>Enter your personal details and start journey with us.</p>
              <button
                id="signup-btn"
                className={isSignup ? "active-btn" : ""}
                onClick={handleToggle}
              >
                No account yet? Signup.
              </button>
            </div>
          </div>
          <div className="intro-control signup-intro">
            <div className="intro-control__inner">
              <Title level={1} className="title-intro">
                Welcome Back!
              </Title>
              <p>
                To keep connected with us please login with your personal info.
              </p>
              <button
                id="signin-btn"
                className={!isSignup ? "active-btn" : ""}
                onClick={handleToggle}
              >
                Already have an account? Signin.
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthForm;
