import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Typography, message, Upload, Modal } from "antd";
const { Dragger } = Upload;
const { Title } = Typography;

function FoldersUpload({ isImportOpen, setIsImportOpen, name }) {
  const props = {
    name: "file",
    multiple: true,
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleOk = () => {
    setIsImportOpen(false);
  };

  const handleCancel = () => {
    setIsImportOpen(false);
  };

  return (
    <div style={{ padding: "12px 0" }}>
      <Modal open={isImportOpen} onOk={handleOk} onCancel={handleCancel}>
        <Title level={3}>{name}</Title>

        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from
            uploading company data or other banned files.
          </p>
        </Dragger>
      </Modal>
    </div>
  );
}

export default FoldersUpload;
