import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
import "./AuthForm.css";

const { Title } = Typography;

const EmergencyVerify = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerify, setIsVerify] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const handleVerify = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_URL}/api/users/login`, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ emergencyCode: verificationCode }),
      });

      if (response.ok) {
        const data = await response.json();

        localStorage.setItem("token", data.token);
        localStorage.setItem("userEmail", data.email);

        setSuccessMessage("Login successful!");
        setErrorMessage(null);

        navigate("/dashboard");
      } else {
        const data = await response.json();
        setErrorMessage(data.error || "Verification failed.");
        setSuccessMessage(null);
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setSuccessMessage(null);
    }
  };

  return (
    <div className="globalStyles">
      <div className={`container ${isVerify ? "change" : ""}`}>
        <div className="forms-container">
          <div className="form-control signin-form">
            <form onSubmit={handleVerify}>
              <div style={{ display: "flex", justifyContent: "center" }}></div>
              <Title level={2}>Emergency Code</Title>
              <input
                type="text"
                placeholder="Emergency Code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
              <button type="submit">Emergency Login</button>
            </form>
            {errorMessage && (
              <p className="text-red-500 text-center mt-3">{errorMessage}</p>
            )}
            {successMessage && (
              <p className="text-green-500 text-center mt-3">
                {successMessage}
              </p>
            )}
          </div>
        </div>
        <div className="intros-container">
          <div className="intro-control signin-intro">
            <div className="intro-control__inner">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="/logo2.png"
                  alt="Logo"
                  width={120}
                  style={{
                    borderRadius: "10px",
                    marginBottom: "4px",
                  }}
                />
              </div>
              <p>
                Please verify your login by entering the emergency code sent to
                your email.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmergencyVerify;
