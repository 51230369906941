import React, { useState, useEffect } from "react";
import { Modal, Select, Button, message } from "antd";
import axios from "axios";

const ShareModal = ({
  visible,
  onCancel,
  itemId,
  itemType,
  userId,
  appId,
  token,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  useEffect(() => {
    const fetchUsersAndTeams = async () => {
      try {
        const usersResponse = await axios.get(
          `${API_URL}/api/invite/${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUsers(usersResponse.data);

        const teamsResponse = await axios.get(
          `${API_URL}/api/users/${userId}/teams`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTeams(teamsResponse.data.teams);
      } catch (error) {
        console.error("Failed to load users and teams:", error);
      }
    };

    if (visible) {
      fetchUsersAndTeams();
    }
  }, [visible, userId, token]);

  const handleShare = async () => {
    if (selectedUsers.length === 0 && selectedTeams.length === 0) {
      message.error("You must select at least one user or team!");
      return;
    }

    try {
      const response = await axios.post(
        `${API_URL}/api/share/share-items/${userId}`,
        {
          itemId,
          itemType,
          appId,
          userIds: selectedUsers.length > 0 ? selectedUsers : [],
          teamIds: selectedTeams.length > 0 ? selectedTeams : [],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        message.success("Sharing successful!");
        onCancel();
      } else {
        message.error("An error occurred while sharing.");
      }
    } catch (error) {
      console.error("Sharing API error:", error);
      message.error("An error occurred while sharing.");
    }
  };

  return (
    <Modal
      title="Paylaş"
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="share" type="primary" onClick={handleShare}>
          Share
        </Button>,
      ]}
    >
      <div>
        <h4>Select User</h4>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select User"
          onChange={(value) => setSelectedUsers(value)}
          options={users.map((user) => ({
            key: user.id,
            value: user.id,
            label: user.name,
          }))}
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <h4>Select Team</h4>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select Team"
          onChange={(value) => setSelectedTeams(value)}
          options={teams.map((team) => ({
            key: team.id,
            value: team.id,
            label: team.name,
          }))}
        />
      </div>
    </Modal>
  );
};

export default ShareModal;
