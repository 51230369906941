import React, { useState, useEffect } from "react";
import { ConfigProvider } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import PasswordManager from "./Pages/passwordManager/PasswordManager";
import LoadingPage from "./components/Loadingpage/LoadingPage";
import LayoutMain from "./components/layout/LayoutMain";
import Verify from "./Pages/auth/Verify";
import UserManagamentPage from "./Pages/Usermanagament/UserManagamentPage";
import Apps from "./Pages/apps/Apps";
import Folders from "./Pages/folders/Folders";
import AuthForm from "./Pages/auth/AuthForm";
import Builder from "./Pages/builder/Builder";
import Emergency from "./Pages/Settings/emergencyAccess/EmergencyAccess";
import Password from "./Pages/Settings/passwordChange/PasswordChange";
import SecureNotes from "./Pages/secureNotes/SecureNotes";
import EmergencyVerify from "./Pages/auth/EmergencyVerify";
// import Logs from "./Pages/logs/Logs";
import ForgotPassword from "./Pages/auth/ForgotPassword";
import SharedItems from "./Pages/sharedItems/SharedItems";
import Profile from "./Pages/profile/Profile";
import NotFound from "./components/notFound/NotFound";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#2C2C2C",
          borderRadius: 5,
        },
      }}
    >
      <Router>
        <Routes>
          <Route path="/Auth" element={<AuthForm />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/emergency-Verify" element={<EmergencyVerify />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route
            path="/apps"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Apps />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          />
          <Route path="/" element={<Navigate to="/auth" replace />} />
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Dashboard />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          />
          <Route
            path="/apps/password-manager"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <PasswordManager />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          />
          <Route
            path="/shared-items"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <SharedItems />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          />
          <Route
            path="/secure-notes"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <SecureNotes />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          />
          <Route
            path="/user-management"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <UserManagamentPage />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          />
          {/* <Route
            path="/settings/logs"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Logs />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          /> */}
          <Route
            path="/settings/emergency-access"
            element={
              <LayoutMain>
                <Emergency />
              </LayoutMain>
            }
          />
          <Route
            path="/settings/Password"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Password />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          />
          <Route
            path="/builder"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Builder />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          />

          <Route
            path="/apps/:appId"
            element={
              <LayoutMain>
                <PasswordManager />
              </LayoutMain>
            }
          />
          <Route
            path="/apps/:appId/:folderId"
            element={
              <LayoutMain>
                <Folders />
              </LayoutMain>
            }
          />
          <Route
            path="/profile"
            element={
              isAuthenticated ? (
                <LayoutMain>
                  <Profile />
                </LayoutMain>
              ) : (
                <Navigate to="/Auth" />
              )
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
};

export default App;
