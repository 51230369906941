import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Space,
  Empty,
  Table,
  Dropdown,
  message,
  Modal,
  Input,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  ImportOutlined,
  ShareAltOutlined,
  DownOutlined,
  MoreOutlined,
  DeleteOutlined,
  SearchOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import ShareModal from "../../components/share/ShareModal";
import SecureNoteNewModal from "./component/SecureNoteNewModal";
import SecureNoteUpdate from "./component/SecureNoteUpdate";
import { Link } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import SecureNoteUpload from "./component/SecureNoteUpload";

const { Title } = Typography;
const { confirm } = Modal;

function SecureNotes() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isCreateModalVisible, setCreateIsModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editNote, setEditNote] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isImportOpen, setIsImportOpen] = useState(false);
  const token = localStorage.getItem("token");
  const decodedToken = token ? JSON.parse(atob(token.split(".")[1])) : null;
  const currentTime = Math.floor(Date.now() / 1000);

  useEffect(() => {
    const loadData = async () => {
      if (decodedToken && decodedToken.exp >= currentTime) {
        try {
          const [appsResponse, notesResponse] = await Promise.all([
            fetchAppNames(token),
            fetchSecureNotes(decodedToken.id, token),
          ]);

          const apps = appsResponse.data.apps || [];
          const formattedOptions = apps.map((app) => ({
            label: app.name,
            value: app.id,
          }));

          const notes = notesResponse.data
            .map((note) => ({
              key: note.note_id,
              name: note.title,
              note: note.note,
              lastUpdate: new Date(note.updated_at).toLocaleString(),
              collections: note.app_id,
            }))
            .map((note) => {
              const app = formattedOptions.find(
                (app) => app.value === note.collections
              );
              return {
                ...note,
                collections: app ? app.label : "Unknown App",
              };
            })
            .sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));

          setData(notes);
          setFilteredData(notes);
        } catch (error) {
          console.error("Error loading data:", error);
          message.error("Failed to load data");
        }
      } else {
        localStorage.removeItem("token");
      }
    };

    loadData();
  }, [isCreateModalVisible, isEditModalVisible, isShareModalVisible]);

  const fetchAppNames = async (token) => {
    return await axios.get(`${API_URL}/api/apps`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const fetchSecureNotes = async (userId, token) => {
    return await axios.get(`${API_URL}/api/secure-notes/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await axios.delete(`${API_URL}/api/secure-notes/delete/${noteId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      message.success("Note deleted successfully");

      setData((prevData) => prevData.filter((note) => note.key !== noteId));
      setFilteredData((prevData) =>
        prevData.filter((note) => note.key !== noteId)
      );
    } catch (error) {
      console.error("Failed to delete note:", error);
      message.error("Failed to delete note");
    }
  };

  const handleBulkDelete = () => {
    const selectedNotes = selectedRowKeys
      .map((key) => data.find((note) => note.key === key))
      .filter((note) => note);

    const noteNames = selectedNotes.map((note) => note.name).join(", ");

    confirm({
      title: "Are you sure you want to delete the selected notes?",
      icon: <DeleteOutlined style={{ color: "red" }} />,
      content: (
        <span>
          <strong>{noteNames}</strong> notes will be deleted. This action cannot
          be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          for (let noteId of selectedRowKeys) {
            await handleDeleteNote(noteId);
          }
          setSelectedRowKeys([]);
        } catch (error) {
          console.error("Bulk delete failed:", error);
          message.error("An error occurred during bulk deletion.");
        }
      },
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filteredNotes = data.filter((note) =>
      note.name.toLowerCase().includes(value)
    );
    setFilteredData(filteredNotes);
  };

  const showDeleteConfirm = (note) => {
    confirm({
      title: `Are you sure you want to delete this note?`,
      icon: <DeleteOutlined style={{ color: "red" }} />,
      content: (
        <span>
          You are about to delete the note: <strong>{note.name}</strong>. This
          action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDeleteNote(note.key);
      },
    });
  };

  const handleNoteUpdateSuccess = (updatedNote) => {
    setData((prevData) =>
      prevData.map((note) =>
        note.key === updatedNote.key ? updatedNote : note
      )
    );

    setFilteredData((prevData) =>
      prevData.map((note) =>
        note.key === updatedNote.key ? updatedNote : note
      )
    );
  };

  const handleExport = () => {
    const dataToExport = filteredData.map((note) => ({
      Name: note.name,
      "Last Update": note.lastUpdate,
      Collections: note.collections,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Secure Notes");

    const fileName = `secure_notes.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const showCreateNoteModal = () => setCreateIsModalVisible(true);
  const showShareModal = () => setIsShareModalVisible(true);
  const showImportModal = () => {
    setIsImportOpen(true);
  };

  const showEditModal = (note) => {
    setEditNote(note);
    setIsEditModalVisible(true);
  };

  const columns = [
    {
      title: "Secure name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link onClick={() => showEditModal(record)}>{text}</Link>
      ),
    },
    {
      title: "Last update",
      dataIndex: "lastUpdate",
      key: "update",
    },
    {
      title: "Collections",
      dataIndex: "collections",
      key: "collections",
    },
    {
      dataIndex: "edit",
      key: "edit",
      render: (text, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Space
                      style={{ color: "red" }}
                      onClick={() => showDeleteConfirm(record)}
                    >
                      <DeleteOutlined /> Delete
                    </Space>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button
              type="text"
              style={{
                color: selectedRowKeys.includes(record.key)
                  ? "white"
                  : "inherit",
              }}
            >
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <div style={{ padding: "12px 0" }}>
      <Title level={3}>Secure Notes</Title>

      <Row gutter={[16, 16]} justify="space-between" align="middle">
        {/* Sol taraftaki butonlar */}
        <Col xs={24} sm={12} md={12} lg={12}>
          <Space direction="horizontal" size="middle" wrap>
            <Button
              icon={<PlusOutlined />}
              onClick={showCreateNoteModal}
              type="primary"
            >
              Add new
            </Button>
            <Button
              onClick={showShareModal}
              disabled={selectedRowKeys.length === 0}
              icon={<ShareAltOutlined />}
            >
              Share
            </Button>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "2",
                    label: (
                      <Space
                        style={{ color: "red" }}
                        onClick={handleBulkDelete}
                      >
                        <DeleteOutlined /> Delete
                      </Space>
                    ),
                  },
                ],
              }}
              trigger={["click"]}
            >
              <Button disabled={selectedRowKeys.length === 0}>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        </Col>

        {/* Sağ taraftaki arama ve dropdown */}
        <Col xs={24} sm={12} md={12} lg={12}>
          <Row gutter={[8, 8]} align="middle" justify="end" wrap={false}>
            <Col>
              <Input
                placeholder="Search..."
                className="search-input-table"
                prefix={<SearchOutlined />}
                value={searchValue}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <Space
                          style={{ width: "100px" }}
                          onClick={showImportModal}
                        >
                          <ImportOutlined /> Import
                        </Space>
                      ),
                    },
                    {
                      key: "2",
                      label: (
                        <Space
                          style={{ width: "100px" }}
                          onClick={handleExport}
                        >
                          <ExportOutlined /> Export
                        </Space>
                      ),
                    },
                  ],
                }}
                placement="bottom"
                arrow
                trigger={["click"]}
              >
                <Button type="text">
                  <MoreOutlined style={{ fontSize: "20px" }} />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ marginTop: 10 }}>
        {filteredData.length === 0 ? (
          <Empty description="No secure notes available" />
        ) : (
          <Table
            dataSource={filteredData}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.key}
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            scroll={{ x: "max-content" }}
          />
        )}
      </div>
      <SecureNoteNewModal
        isModalVisible={isCreateModalVisible}
        setIsModalVisible={setCreateIsModalVisible}
      />
      <SecureNoteUpdate
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
        editNote={editNote}
        onSuccess={handleNoteUpdateSuccess}
      />
      <SecureNoteUpload
        isImportOpen={isImportOpen}
        setIsImportOpen={setIsImportOpen}
        name="Secure Note İmport"
      />
      <ShareModal
        isModalVisible={isShareModalVisible}
        setIsModalVisible={setIsShareModalVisible}
      />
    </div>
  );
}

export default SecureNotes;
