import React, { useState } from "react";
import axios from "axios";
import { Typography, Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import "./PasswordChange.css";

const { Title } = Typography;

const PasswordChange = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSave = async (values) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("token");
    const decodedToken = JSON.parse(atob(token.split(".")[1]));
    const userId = decodedToken.id || decodedToken.userId;
    setLoading(true);
    try {
      await axios.post(
        `${API_URL}/api/users/change-password/${userId}`,
        {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      message.success("Password changed successfully");
    } catch (error) {
      console.error("Error changing password:", error);
      message.error("Failed to change password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "12px 0" }}>
      <Title level={2}>Change Password</Title>
      <Form form={form} onFinish={handleSave} layout="vertical">
        <Form.Item
          name="currentPassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: "Please input your current password!",
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined />} className="changeInput" />
        </Form.Item>
        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input the new password!",
            },
            {
              min: 9,
              message: "Password must be at least 9 characters!",
            },
            {
              pattern: /(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.,])/,
              message:
                "Password must include at least one uppercase letter, one number, and one special character!",
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined />} className="changeInput" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm New Password"
          dependencies={["newPassword"]}
          rules={[
            {
              required: true,
              message: "Please confirm your new password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password prefix={<LockOutlined />} className="changeInput" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordChange;
