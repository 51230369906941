// LoadingPage.js
import React from "react";
import { Spin } from "antd";
import "./style.css";

const LoadingPage = () => {
  return (
    <div className="spin">
      <Spin size="large" />
    </div>
  );
};

export default LoadingPage;
