import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Tag,
  Typography,
  message,
  Modal,
  Row,
  Col,
  Input,
} from "antd";
import {
  EyeOutlined,
  CopyOutlined,
  EyeInvisibleOutlined,
  FolderOutlined,
  KeyOutlined,
  ArrowLeftOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import "./SharedItems.css";

const { Title } = Typography;

const SharedItems = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [showSecretValues, setShowSecretValues] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFolderContents, setSelectedFolderContents] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [backStack, setBackStack] = useState([]);

  const fetchSharedItems = async (userId, token) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/share/shared-items/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.sharedItems && response.data.sharedItems.length > 0) {
        setData(response.data.sharedItems);
        setFilteredData(response.data.sharedItems);
      } else {
        message.info("There are no shared items.");
      }
    } catch (error) {
      console.error("Error fetching shared items:", error);
      message.error("An error occurred while fetching shared items.");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setUserId(decodedToken.id);
      fetchSharedItems(decodedToken.id, token);
    } else {
      message.error("Token not found.");
    }
  }, []);

  const toggleSecretVisibility = (id) => {
    setShowSecretValues((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const openFolderModal = (contents, folderName) => {
    if (selectedFolderContents.length > 0) {
      setBackStack((prevStack) => [
        ...prevStack,
        { contents: selectedFolderContents, title: modalTitle },
      ]);
    }
    setSelectedFolderContents(contents);
    setFilteredData(contents);
    setModalTitle(folderName);
    setIsModalVisible(true);
  };

  const goBack = () => {
    const lastState = backStack.pop();
    if (lastState) {
      setSelectedFolderContents(lastState.contents);
      setFilteredData(lastState.contents);
      setModalTitle(lastState.title);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setBackStack([]);
    setSelectedFolderContents([]);
    setModalTitle("");
    setFilteredData(data);
  };
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filteredApps = data.filter((app) =>
      app.name.toLowerCase().includes(value)
    );
    setFilteredData(filteredApps);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          {record.type === "Folder" ? (
            <span
              onClick={() => openFolderModal(record.contents, record.name)}
              style={{ cursor: "pointer" }}
            >
              <FolderOutlined /> <strong>{text}</strong>
            </span>
          ) : (
            <span>
              <KeyOutlined /> <strong>{text}</strong>
            </span>
          )}
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Tag color={type === "Folder" ? "blue" : "green"}>{type}</Tag>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) =>
        record.type === "Secret" ? (
          <div className="flex justify-between items-center">
            <span>{showSecretValues[record.id] ? text : "••••••••"}</span>
            <Space>
              <Button
                icon={
                  showSecretValues[record.id] ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                type="text"
                onClick={() => toggleSecretVisibility(record.id)}
              />
              <Button
                icon={<CopyOutlined />}
                type="text"
                onClick={() => {
                  navigator.clipboard.writeText(text);
                  message.success("Secret copied!");
                }}
              />
            </Space>
          </div>
        ) : (
          "-"
        ),
    },
  ];

  return (
    <div style={{ padding: "12px 0" }}>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="middle"
        wrap
        style={{ marginBottom: 10 }}
      >
        <Col xs={24} md={12}>
          <Title level={3} style={{ margin: 0, lineHeight: "32px" }}>
            Shared Items
          </Title>
        </Col>
        <Col xs={24} md={12} style={{ display: "flex" }} className="search-col">
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            value={searchValue}
            onChange={handleSearch}
            style={{ width: "100%", maxWidth: 200 }}
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={{ pageSize: 8 }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title={
          <Row align="middle" gutter={[8, 8]}>
            {backStack.length > 0 && (
              <Col style={{ marginTop: "2px" }}>
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined />}
                  onClick={goBack}
                >
                  Back
                </Button>
              </Col>
            )}
            <Col flex="auto">
              <Title level={3} style={{ margin: 0 }}>
                {modalTitle}
              </Title>
            </Col>
          </Row>
        }
        open={isModalVisible}
        onCancel={closeModal}
        footer={null}
        width="80%"
        styles={{ body: { overflowX: "auto" } }}
      >
        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          rowKey={(record) => record.id}
          scroll={{ x: "max-content" }}
        />
      </Modal>
    </div>
  );
};

export default SharedItems;
