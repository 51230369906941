import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import axios from "axios";

const CreateFolderModal = ({
  isFolderModalVisible,
  setIsFolderModalVisible,
  folderId,
  fetchFolders,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [folderForm] = Form.useForm();

  const handleCreateFolder = async (values) => {
    const token = localStorage.getItem("token");
    try {
      await axios.post(
        `${API_URL}/api/folders/${folderId}`,
        { ...values, parent_id: folderId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchFolders(token);
      setIsFolderModalVisible(false);
      folderForm.resetFields();
      message.success("Folder created successfully");
    } catch (error) {
      console.error("Error creating folder:", error);
      message.error("Failed to create folder");
    }
  };

  return (
    <Modal
      title="Create New Folder"
      open={isFolderModalVisible}
      onCancel={() => setIsFolderModalVisible(false)}
      footer={null}
    >
      <Form form={folderForm} onFinish={handleCreateFolder}>
        <Form.Item
          name="name"
          label="Folder Name"
          rules={[
            {
              required: true,
              message: "Please input the name of the folder!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateFolderModal;
