import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import axios from "axios";

const EditSecretModal = ({
  isEditModalVisible,
  setIsEditModalVisible,
  editingSecret,
}) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [editForm] = Form.useForm();

  const handleUpdateSecretBySecretId = async (secretId, values) => {
    const token = localStorage.getItem("token");
    try {
      await axios.put(`${API_URL}/api/secrets/${secretId}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      editForm.resetFields();
      setIsEditModalVisible(false);
      message.success("Secret updated successfully");
    } catch (error) {
      console.error("Error updating secret:", error);
      message.error("Failed to update secret");
    }
  };

  return (
    <Modal
      title="Edit Secret"
      open={isEditModalVisible}
      onCancel={() => setIsEditModalVisible(false)}
      footer={null}
    >
      <Form
        form={editForm}
        onFinish={(values) =>
          handleUpdateSecretBySecretId(editingSecret.secret_id, values)
        }
      >
        <Form.Item
          name="name"
          label="Secret Name"
          rules={[
            {
              required: true,
              message: "Please input the name of the secret!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="value"
          label="Secret Value"
          rules={[
            {
              required: true,
              message: "Please input the value of the secret!",
            },
          ]}
        >
          <Input type="password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditSecretModal;
