import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Card,
  Typography,
  Space,
  Button,
  Modal,
  Form,
  Input,
  Select,
  message,
  Dropdown,
  Row,
  Col,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  DeleteOutlined,
  MoreOutlined,
  DownOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const UserManagement = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [dataSource, setDataSource] = useState([]);
  const [isCreateUserModalVisible, setIsCreateUserModalVisible] =
    useState(false);
  const [isRegisterUserModalVisible, setIsRegisterUserModalVisible] =
    useState(false);
  const [createUserForm] = Form.useForm();
  const [registerUserForm] = Form.useForm();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      setUserId(decodedToken.id);
      fetchUsers(decodedToken.id, token);
    } else {
      message.error("Token not found.");
    }
  }, []);

  const fetchUsers = async (userId, token) => {
    try {
      const response = await axios.get(`${API_URL}/api/invite/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDataSource(response.data);
    } catch (error) {
      console.error("An error occurred while fetching users:", error);
      message.error("An error occurred while fetching users.");
    }
  };

  const showCreateUserModal = () => {
    setIsCreateUserModalVisible(true);
  };

  const showRegisterUserModal = () => {
    setIsRegisterUserModalVisible(true);
  };

  const handleCreateUserCancel = () => {
    setIsCreateUserModalVisible(false);
    createUserForm.resetFields();
  };

  const handleRegisterUserCancel = () => {
    setIsRegisterUserModalVisible(false);
    registerUserForm.resetFields();
  };

  const handleCreateUser = async (values) => {
    const token = localStorage.getItem("token");
    try {
      await axios.post(`${API_URL}/api/invited-users/create`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (userId) {
        fetchUsers(userId, token);
      }
      setIsCreateUserModalVisible(false);
      createUserForm.resetFields();
      message.success(
        "The user has been successfully invited and the email has been sent."
      );
    } catch (error) {
      console.error("An error occurred while inviting the user:", error);
      message.error("An error occurred while inviting the user");
    }
  };

  const handleRegisterUser = async (values) => {
    const token = localStorage.getItem("token");
    try {
      await axios.post(
        `${API_URL}/api/invite-registered`,
        { email: values.email },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      message.success("Invitation email successfully sent to registered user.");
      setIsRegisterUserModalVisible(false);
      registerUserForm.resetFields();
    } catch (error) {
      console.error(
        "An error occurred while sending an invitation to a registered user:",
        error.response?.data || error.message
      );
      message.error(
        "An error occurred while sending an invitation to a registered user."
      );
    }
  };

  const handleDeleteUser = async (userId) => {
    const token = localStorage.getItem("token");
    try {
      await axios.delete(`${API_URL}/api/invite/remove/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDataSource((prevDataSource) =>
        prevDataSource.filter((user) => user.id !== userId)
      );
      message.success("User deleted successfully.");
    } catch (error) {
      console.error("An error occurred while deleting the user:", error);
      message.error("An error occurred while deleting the user.");
    }
  };

  const columns = [
    { title: "User Name", dataIndex: "name", key: "name" },
    { title: "Role", dataIndex: "role", key: "role" },
    {
      key: "actions",
      render: (text, record) => (
        <Space size="middle" style={{ float: "right" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Space
                      onClick={() => handleDeleteUser(record.id)}
                      type="primary"
                      danger
                      style={{
                        width: "100px",
                        color: "red",
                      }}
                    >
                      <DeleteOutlined /> Delete
                    </Space>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button type="text">
              <MoreOutlined style={{ fontSize: "20px" }} />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Title level={2}>User Management</Title>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Card
          title={
            <Row
              gutter={[4, 4]}
              justify="space-between"
              align="middle"
              wrap
              style={{ marginBottom: 10 }}
            >
              <Col
                xs={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Title
                  level={5}
                  style={{ margin: 0, lineHeight: "32px", width: "100%" }}
                >
                  Invited Users
                </Title>
              </Col>

              <Col
                xs={24}
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="button-col"
              >
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: (
                          <Space onClick={showCreateUserModal}>
                            <UserOutlined />
                            Invite User
                          </Space>
                        ),
                      },
                      {
                        label: (
                          <Space onClick={showRegisterUserModal}>
                            <UserOutlined />
                            Invite Registered User
                          </Space>
                        ),
                      },
                    ],
                  }}
                  placement="bottom"
                  arrow
                  trigger={["click"]}
                >
                  <Button onClick={(e) => e.preventDefault()}>
                    <Space>
                      Invite <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          }
          style={{ marginBottom: "16px" }}
        >
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowKey="id"
            scroll={{ x: "max-content" }}
          />
        </Card>
      </Space>

      <Modal
        title="Create User"
        open={isCreateUserModalVisible}
        onCancel={handleCreateUserCancel}
        footer={null}
      >
        <Form form={createUserForm} onFinish={handleCreateUser}>
          <Form.Item
            name="name"
            label="User Name"
            rules={[
              { required: true, message: "Please enter the user's name!" },
            ]}
          >
            <Input prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            name="email"
            label="User Email"
            rules={[
              {
                required: true,
                message: "Please enter the user's email!",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item
            name="role"
            label="User Role"
            rules={[
              { required: true, message: "Please select the user's role!" },
            ]}
          >
            <Select
              options={[
                { value: "admin", label: "Admin" },
                { value: "manager", label: "Manager" },
                { value: "user", label: "User" },
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Invite Registered User"
        open={isRegisterUserModalVisible}
        onCancel={handleRegisterUserCancel}
        footer={null}
      >
        <Form form={registerUserForm} onFinish={handleRegisterUser}>
          <Form.Item
            name="email"
            label="User Email"
            rules={[
              {
                required: true,
                message: "Please enter the user's email!",
              },
            ]}
          >
            <Input prefix={<MailOutlined />} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Invite
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <style jsx>{`
        @media (min-width: 768px) {
          .button-col {
            justify-content: flex-end; /* Geniş ekranda sağa hizalama */
          }
        }
      `}</style>
    </div>
  );
};

export default UserManagement;
