import React from "react";
import { Tabs } from "antd";
import TeamManagement from "./TeamManagament";
import UserManagement from "./UserManagament";

const UserManagementPage = () => {
  const items = [
    {
      key: "1",
      label: "Team Management",
      children: <TeamManagement />,
    },
    {
      key: "2",
      label: "User Management",
      children: <UserManagement />,
    },
  ];
  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default UserManagementPage;
