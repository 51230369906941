import React, { useState } from "react";
import { Button, Typography, Modal, Input, message } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import "./EmergencyAccess.css";

const { Title } = Typography;

function EmergencyAccess() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      message.error("Token not found.");
      return;
    }

    if (!email) {
      message.error("Please enter an email address.");
      return;
    }

    setLoading(true);
    try {
      await axios.post(
        `${API_URL}/api/send-emergency-code`,
        { email },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      message.success("Emergency code sent successfully!");
      setIsModalVisible(false);
    } catch (error) {
      console.error("An error occurred:", error);

      if (error.response) {
        console.error("API Error:", error.response.data);
        if (error.response.status === 404) {
          message.error("Emergency code not found.");
        } else if (error.response.status === 401) {
          message.error("Authorization error, please log in.");
        } else {
          message.error("An error occurred, please try again.");
        }
      } else {
        message.error(
          "The server could not be reached, please check your connection."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: "12px 0" }}>
      <Title level={1}>Emergency Access</Title>
      <p className="info">
        Grant emergency access permissions for trusted contacts and manage
        permissions. Trusted contacts can request to view or take over your
        account in an emergency. To learn how zero-knowledge proof sharing
        works, check our help page.{" "}
        <Link to="#" style={{ color: "red" }}>
          Get more information.
        </Link>
      </p>
      <div className="emergency-contact">
        <Title level={3}>Trusted emergency contacts</Title>
        <p className="info">
          You haven't added any emergency contacts yet. Invite someone you trust
          to get started.
        </p>
        <Button className="emergency-button" onClick={showModal}>
          Add an emergency contact
        </Button>
      </div>
      <Title level={3}>Set as an emergency contact</Title>
      <p className="info">No one has set you as an emergency contact yet.</p>

      <Modal
        title="Add Emergency Contact"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        okText="Send Code"
        cancelText="Cancel"
      >
        <p>
          Please enter the email address of the person you want to add as an
          emergency contact:
        </p>
        <Input
          type="email"
          placeholder="Enter email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Modal>
    </div>
  );
}

export default EmergencyAccess;
