import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import {
  Space,
  Button,
  Dropdown,
  Typography,
  Empty,
  Input,
  message,
  Table,
  Modal,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  FolderOutlined,
  KeyOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CopyOutlined,
  MoreOutlined,
  ShareAltOutlined,
  DownOutlined,
  SearchOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import CreateSecretModal from "./component/CreateSecretModal";
import CreateFolderModal from "./component/CreateFolderModal";
import EditFolderModal from "../passwordManager/component/EditFolderModal";
import EditSecretModal from "../passwordManager/component/EditSecretModal";
import ShareModal from "../../components/share/ShareModal";
import FoldersUpload from "./component/FoldersUpload";

const { Title, Text } = Typography;

const Folders = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [secrets, setSecrets] = useState([]);
  const [folders, setFolders] = useState([]);
  const [appName, setAppName] = useState("");
  const [userId, setUserId] = useState(null);
  const [folderName, setFolderName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFolderModalVisible, setIsFolderModalVisible] = useState(false);
  const [isFolderEditModalVisible, setIsFolderEditModalVisible] =
    useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingFolder, setEditingFolder] = useState(null);
  const [editingSecret, setEditingSecret] = useState(null);
  const [showSecretValues, setShowSecretValues] = useState({});
  const [selectionType, setSelectionType] = useState("checkbox");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isImportOpen, setIsImportOpen] = useState(false);

  const { appId, folderId } = useParams();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      try {
        const decodedToken = JSON.parse(atob(storedToken.split(".")[1]));
        const userIdFromToken = decodedToken.id || decodedToken.userId;
        setUserId(userIdFromToken);
      } catch (error) {
        console.error("Token decode edilemedi:", error);
      }

      if (folderId) {
        fetchSecretsByFolder(storedToken);
        fetchSubFolders(storedToken);
      } else {
        fetchRootFolders(storedToken);
        fetchSecretsByApp(storedToken);
      }
      fetchFolderDetails(storedToken);
      fetchAppDetails(storedToken);
    }
  }, [folderId]);

  useEffect(() => {
    setFilteredData([...secrets, ...folders]);
  }, [secrets, folders]);

  const fetchFolderDetails = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/folders/app/${appId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.folders && response.data.folders.length > 0) {
        if (folderId) {
          const currentFolder = response.data.folders.find(
            (folder) => folder.id === folderId
          );
          if (currentFolder) {
            setFolderName(currentFolder.name);
          } else {
            setFolderName("Unknown Folder");
          }
        } else {
          setFolderName(response.data.folders[0].name);
        }
      } else {
        setFolderName("No Folders Available");
      }
    } catch (error) {
      console.error("Error fetching folder details:", error);
    }
  };

  const fetchAppDetails = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/apps/${appId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAppName(response.data.name);
    } catch (error) {
      console.error("Error fetching app details:", error);
    }
  };

  const fetchSecretsByApp = async (token) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/secrets/${appId}/secrets`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const filteredSecrets = response.data.secrets.filter(
        (secret) => !secret.folder_id
      );

      setSecrets(
        filteredSecrets.map((secret) => ({ ...secret, type: "Secret" }))
      );
    } catch (error) {
      console.error("Error fetching secrets by app:", error);
      message.error("Failed to fetch secrets for the app.");
    }
  };

  const fetchSecretsByFolder = async (token) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/secrets/folders/${folderId}/secrets`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSecrets(
        response.data.secrets.map((secret) => ({ ...secret, type: "Secret" }))
      );
    } catch (error) {
      console.error("Error fetching secrets by folder:", error);
      message.error("Failed to fetch secrets for the folder.");
    }
  };

  const fetchSubFolders = async (token) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/folders/folder/${folderId}/subfolders`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setFolders(
        response.data.folders.map((folder) => ({ ...folder, type: "Folder" }))
      );
    } catch (error) {
      console.error("Error fetching subfolders:", error);
      message.error("Failed to fetch subfolders.");
    }
  };

  const fetchRootFolders = async (token) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/folders/${appId}/rootfolders`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setFolders(
        response.data.folders.map((folder) => ({ ...folder, type: "Folder" }))
      );
    } catch (error) {
      console.error("Error fetching root folders:", error);
      message.error("Failed to fetch root folders.");
    }
  };

  const toggleSecretVisibility = (id) => {
    setShowSecretValues((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const showCreateSecretModal = () => {
    setIsModalVisible(true);
  };

  const showCreateFolderModal = () => {
    setIsFolderModalVisible(true);
  };

  const handleDeleteFolder = async (folder) => {
    Modal.confirm({
      title: "Are you sure you want to delete this folder?",
      content: (
        <span>
          You are about to delete the folder: <Text strong>{folder.name}</Text>.
          This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const token = localStorage.getItem("token");
        try {
          await axios.delete(`${API_URL}/api/folders/${folder.id}/delete`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (folderId) {
            fetchSubFolders(token);
          } else {
            fetchRootFolders(token);
          }
          message.success(`Folder "${folder.name}" deleted successfully`);
        } catch (error) {
          console.error("Error deleting folder:", error);
          message.error("Failed to delete folder.");
        }
      },
    });
  };

  const handleDeleteSecret = async (secretId, secretName) => {
    Modal.confirm({
      title: "Are you sure you want to delete this secret?",
      content: (
        <span>
          You are about to delete the secret: <Text strong>{secretName}</Text>.
          This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const token = localStorage.getItem("token");
        const url = `${API_URL}/api/secrets/${secretId}`;

        try {
          const response = await axios.delete(url, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            if (folderId) {
              fetchSecretsByFolder(token);
            } else {
              fetchSecretsByApp(token);
            }
            message.success(`Secret "${secretName}" deleted successfully`);
          } else {
            message.error("Failed to delete secret");
          }
        } catch (error) {
          console.error("Error deleting secret:", error);
          message.error("Failed to delete secret");
        }
      },
    });
  };

  const handleDeleteSelected = () => {
    const selectedItems = selectedRowKeys
      .map((id) => filteredData.find((item) => item.id === id))
      .filter((item) => item);

    const itemNames = selectedItems.map((item) => item.name).join(", ");

    Modal.confirm({
      title: "Are you sure you want to delete the selected items?",
      content: (
        <span>
          You are about to delete the following items:{" "}
          <Text strong>{itemNames}</Text>. This action cannot be undone.
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const token = localStorage.getItem("token");

        try {
          for (const item of selectedItems) {
            if (item.type === "Folder") {
              await handleDeleteFolder(item);
            } else if (item.type === "Secret") {
              await handleDeleteSecret(item.secret_id, item.name);
            }
          }

          if (folderId) {
            fetchSubFolders(token);
            fetchSecretsByFolder(token);
          } else {
            fetchRootFolders(token);
            fetchSecretsByApp(token);
          }

          setSelectedRowKeys([]);
          message.success("Selected items deleted successfully.");
        } catch (error) {
          console.error("Error deleting selected items:", error);
          message.error("Failed to delete selected items.");
        }
      },
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
    const filteredApps = [...secrets, ...folders].filter((item) =>
      item.name.toLowerCase().includes(value)
    );
    setFilteredData(filteredApps);
  };

  const handleExport = () => {
    const dataToExport = filteredData.map((item) => ({
      Name: item.name,
      Type: item.type,
      Value: item.type === "Secret" ? item.value : "-",
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Folders Data");

    const fileName = `${appName.replace(/\s+/g, "-")}-folders_data.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const handleShareClick = (record) => {
    setSelectedRecord(record);
    setIsShareModalVisible(true);
  };

  const showImportModal = () => {
    setIsImportOpen(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Space>
          {record.type === "Folder" ? (
            <Link to={`/apps/${appId}/${record.id}`}>
              <FolderOutlined /> <strong>{text}</strong>
            </Link>
          ) : (
            <span>
              <KeyOutlined /> <strong>{text}</strong>
            </span>
          )}
        </Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text, record) =>
        record.type === "Secret" ? (
          <div className="flex justify-between items-center">
            <span>{showSecretValues[record.id] ? text : "••••••••"}</span>
            <Space>
              <Button
                icon={
                  showSecretValues[record.id] ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                type="text"
                style={{
                  color: selectedRowKeys.includes(record.id)
                    ? "#fff"
                    : "inherit",
                }}
                onClick={() => toggleSecretVisibility(record.id)}
              />
              <Button
                icon={<CopyOutlined />}
                type="text"
                style={{
                  color: selectedRowKeys.includes(record.id)
                    ? "#fff"
                    : "inherit",
                }}
                onClick={() => navigator.clipboard.writeText(text)}
              />
            </Space>
          </div>
        ) : (
          "-"
        ),
    },
    // {
    //   title: "shared",
    //   dataIndex: "shared",
    //   key: "shared",
    // },
    {
      key: "actions",
      render: (text, record) => (
        <Space style={{ display: "flex", justifyContent: "end" }}>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <Space
                      onClick={() =>
                        record.type === "Folder"
                          ? (setEditingFolder(record),
                            setIsFolderEditModalVisible(true))
                          : (setEditingSecret(record),
                            setIsEditModalVisible(true))
                      }
                      style={{ width: "100px" }}
                    >
                      <EditOutlined /> Edit
                    </Space>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Space
                      onClick={() => {
                        handleShareClick(record);
                        setIsShareModalVisible(true);
                      }}
                      style={{ width: "100px" }}
                    >
                      <ShareAltOutlined /> Share
                    </Space>
                  ),
                },
                {
                  key: "3",
                  label: (
                    <Space
                      onClick={() =>
                        record.type === "Folder"
                          ? handleDeleteFolder(record)
                          : handleDeleteSecret(record.secret_id, record.name)
                      }
                      type="primary"
                      danger
                      style={{
                        width: "100px",
                        color: "red",
                      }}
                    >
                      <DeleteOutlined /> Delete
                    </Space>
                  ),
                },
              ],
            }}
            placement="bottom"
            arrow
            trigger={["click"]}
          >
            <Button type="text">
              <MoreOutlined
                style={{
                  fontSize: "20px",
                  color: selectedRowKeys.includes(record.id)
                    ? "#fff"
                    : "inherit",
                }}
              />
            </Button>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <div style={{ padding: "12px 0" }}>
      <Title level={2}>{appName}</Title>
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row gutter={[16, 16]} justify="space-between" align="middle">
          {/* Sol taraftaki butonlar */}
          <Col xs={24} sm={12} md={12} lg={12}>
            <Space direction="horizontal" size="middle" wrap>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <Button
                          icon={<PlusOutlined />}
                          onClick={showCreateSecretModal}
                        >
                          Create Secret
                        </Button>
                      ),
                    },
                    {
                      key: "2",
                      label: (
                        <Button
                          icon={<PlusOutlined />}
                          onClick={showCreateFolderModal}
                        >
                          Create Folder
                        </Button>
                      ),
                    },
                  ],
                }}
                placement="bottom"
                arrow
                trigger={["click"]}
              >
                <Button type="primary">
                  <PlusOutlined /> Create
                </Button>
              </Dropdown>
              <Button
                onClick={() => {
                  if (selectedRowKeys.length > 0) {
                    const selectedItem = [...folders, ...secrets].find(
                      (item) => item.id === selectedRowKeys[0]
                    );
                    if (selectedItem) {
                      setSelectedRecord(selectedItem);
                      setIsShareModalVisible(true);
                    }
                  }
                }}
                disabled={selectedRowKeys.length === 0}
                icon={<ShareAltOutlined />}
              >
                Share
              </Button>
              <Dropdown
                disabled={selectedRowKeys.length === 0}
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <Space
                          style={{ color: "red" }}
                          onClick={handleDeleteSelected}
                        >
                          <DeleteOutlined /> Delete
                        </Space>
                      ),
                    },
                  ],
                }}
                placement="bottom"
                arrow
                trigger={["click"]}
              >
                <Button>
                  Actions <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </Col>

          {/* Sağ taraftaki arama ve dropdown */}
          <Col xs={24} sm={12} md={12} lg={12}>
            <Row gutter={[8, 8]} align="middle" justify="end" wrap={false}>
              <Col>
                <Input
                  placeholder="Search..."
                  prefix={<SearchOutlined />}
                  value={searchValue}
                  onChange={handleSearch}
                  style={{ width: "100%", maxWidth: 250 }}
                />
              </Col>
              <Col>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: (
                          <Space
                            style={{ width: "100px" }}
                            onClick={showImportModal}
                          >
                            <ImportOutlined /> İmport
                          </Space>
                        ),
                      },
                      {
                        key: "2",
                        label: (
                          <Space
                            style={{ width: "100px" }}
                            onClick={handleExport}
                          >
                            <ExportOutlined /> Export
                          </Space>
                        ),
                      },
                    ],
                  }}
                  placement="bottom"
                  arrow
                  trigger={["click"]}
                >
                  <Button type="text">
                    <MoreOutlined style={{ fontSize: "20px" }} />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>

        {secrets.length + folders.length === 0 ? (
          <Empty description="No secrets or folders available" />
        ) : (
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            rowKey={(record) => record.id}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            scroll={{ x: "max-content" }}
          />
        )}
      </Space>
      <CreateSecretModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        folderId={folderId}
        fetchSecrets={folderId ? fetchSecretsByFolder : fetchSecretsByApp}
      />
      <CreateFolderModal
        isFolderModalVisible={isFolderModalVisible}
        setIsFolderModalVisible={setIsFolderModalVisible}
        folderId={folderId}
        fetchFolders={folderId ? fetchSubFolders : fetchRootFolders}
      />
      <EditFolderModal
        isFolderEditModalVisible={isFolderEditModalVisible}
        setIsFolderEditModalVisible={setIsFolderEditModalVisible}
        editingFolder={editingFolder}
      />
      <EditSecretModal
        isEditModalVisible={isEditModalVisible}
        setIsEditModalVisible={setIsEditModalVisible}
        editingSecret={editingSecret}
      />
      <ShareModal
        visible={isShareModalVisible}
        onCancel={() => setIsShareModalVisible(false)}
        itemId={selectedRecord?.id}
        itemType={selectedRecord?.type.toLowerCase()}
        appId={appId}
        userId={userId}
        token={localStorage.getItem("token")}
      />
      <FoldersUpload
        isImportOpen={isImportOpen}
        setIsImportOpen={setIsImportOpen}
        name={appName}
      />
    </div>
  );
};

export default Folders;
