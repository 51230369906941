import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  Avatar,
  Button,
  Modal,
  Select,
  message,
  Typography,
  Row,
  Col,
  Table,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Title } = Typography;

const TeamUsers = ({ team }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [inviteUsers, setInviteUsers] = useState([]);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    if (team && team.id) {
      fetchTeamUsers(team.id);
    }
  }, [team]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      fetchInviteUsers(decodedToken.id, token);
    } else {
      message.error("Token not found.");
    }
  }, []);

  const fetchTeamUsers = async (teamId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${API_URL}/api/team/${teamId}/members`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data && Array.isArray(response.data)) {
        setUsers(response.data);
      } else {
        message.error("User data is not in the expected format.");
      }
    } catch (error) {
      console.error("An error occurred while fetching users:", error);
      message.error("An error occurred while fetching users.");
    }
  };

  const fetchInviteUsers = async (userId, token) => {
    try {
      const response = await axios.get(`${API_URL}/api/invite/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const invitedUsers = response.data.map((user) => ({
        id: user.id,
        name: user.name,
        role: user.role,
      }));
      setInviteUsers(invitedUsers);
    } catch (error) {
      console.error("An error occurred while fetching users:", error);
      message.error("An error occurred while fetching users.");
    }
  };

  const handleAddUser = async () => {
    const token = localStorage.getItem("token");

    try {
      await axios.post(
        `${API_URL}/api/team/add-user`,
        {
          teamId: team.id,
          userId: selectedUserId,
          role: "user",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchTeamUsers(team.id);
      setIsInviteModalVisible(false);
      message.success("Kullanıcı başarıyla eklendi.");
    } catch (error) {
      console.error("An error occurred while adding user:", error);
      message.error("An error occurred while adding user.");
    }
  };

  const handleRemoveUser = async (userId, teamId) => {
    const token = localStorage.getItem("token");

    try {
      await axios.post(
        `${API_URL}/api/teams/remove-user`,
        {
          teamId,
          userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchTeamUsers(teamId);
      message.success("Kullanıcı başarıyla çıkarıldı.");
    } catch (error) {
      console.error("An error occurred while removing user:", error);
      message.error("An error occurred while removing user.");
    }
  };

  const showInviteModal = async () => {
    const token = localStorage.getItem("token");
    const userId = JSON.parse(atob(token.split(".")[1])).id;

    await fetchInviteUsers(userId, token);
    setIsInviteModalVisible(true);
  };

  const handleInviteModalCancel = () => {
    setIsInviteModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, user) => (
        <span>
          <Avatar style={{ marginRight: 8 }}>
            {user.name ? user.name[0] : "?"}
          </Avatar>
          {user.name || "Unknown"}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => text || "Email not available",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, user) => (
        <Button
          type="danger"
          onClick={() => handleRemoveUser(user.id, team.id)}
          style={{ width: "100%", maxWidth: 200 }}
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <>
      <Card
        title={
          <Row
            gutter={[4, 4]}
            justify="space-between"
            align="middle"
            wrap
            style={{ marginBottom: 10 }}
          >
            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Title
                level={5}
                style={{ margin: 0, lineHeight: "32px", width: "100%" }}
              >
                {`${team.name} Users`}
              </Title>
            </Col>

            <Col
              xs={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="button-col"
            >
              <Button
                type="primary"
                onClick={showInviteModal}
                style={{ maxWidth: 150 }}
                icon={<PlusOutlined />}
              >
                Add User
              </Button>
            </Col>
          </Row>
        }
        style={{ marginTop: "16px" }}
      >
        {users.length === 0 ? (
          <p>There are no users in this team.</p>
        ) : (
          <Table
            dataSource={users}
            rowKey={(user) => user.id}
            pagination={{ pageSize: 5 }}
            columns={columns}
            scroll={{ x: "max-content" }}
          />
        )}
      </Card>

      <Modal
        title="Invite User"
        open={isInviteModalVisible}
        onCancel={handleInviteModalCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleInviteModalCancel}
            style={{ width: "100%", maxWidth: 200 }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAddUser}
            style={{ width: "100%", maxWidth: 200 }}
          >
            Add
          </Button>,
        ]}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Invited Users"
          onChange={setSelectedUserId}
          value={selectedUserId}
        >
          {inviteUsers.map((user) => (
            <Option key={user.id} value={user.id}>
              {user.name} - {user.email}
            </Option>
          ))}
        </Select>
      </Modal>
      <style jsx>{`
        @media (min-width: 768px) {
          .button-col {
            justify-content: flex-end; /* Geniş ekranda sağa hizalama */
          }
        }
      `}</style>
    </>
  );
};

export default TeamUsers;
