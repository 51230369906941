import React, { useState, useEffect } from "react";
import {
  SafetyOutlined,
  DashboardOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShareAltOutlined,
  SettingOutlined,
  FileProtectOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Layout,
  Menu,
  theme,
  Dropdown,
  Switch,
  ConfigProvider,
  Space,
} from "antd";
import { useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./style.css";
import { Footer } from "antd/es/layout/layout";

const { Header, Content, Sider } = Layout;

const HoverableLink = ({ to, collapsed, children, isDarkMode }) => {
  const [hovered, setHovered] = useState(false);
  const location = useLocation();
  const isSelected = location.pathname === to;

  const linkStyle = {
    color: isDarkMode
      ? "white"
      : collapsed
      ? isSelected
        ? "white"
        : hovered
        ? "white"
        : "black"
      : "white",
  };

  return (
    <Link
      to={to}
      style={linkStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}
    </Link>
  );
};

const LayoutMain = ({ children }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("isDarkMode");
    return savedTheme ? JSON.parse(savedTheme) : false;
  });
  const [collapsed, setCollapsed] = useState(false);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  const isDashboard =
    pathSnippets.length === 0 || pathSnippets[0] === "dashboard";

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 885) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchBreadcrumbItems = async () => {
      try {
        const token = localStorage.getItem("token");

        const items = await Promise.all(
          pathSnippets.map(async (snippet, index) => {
            const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
            let displayName = snippet;
            let breadcrumbItem;

            if (
              snippet.match(/^[0-9a-fA-F]{24}$/) ||
              snippet.match(/^[0-9a-fA-F-]{36}$/)
            ) {
              return null;
            }

            if (pathSnippets[0] === "apps" && index === 1) {
              const response = await axios.get(
                `${API_URL}/api/apps/${snippet}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              displayName = response.data.name;
              breadcrumbItem = (
                <Breadcrumb.Item key={url}>
                  <Link to={url}>{displayName}</Link>
                </Breadcrumb.Item>
              );
            } else if (pathSnippets[0] === "folders" && index === 2) {
              const response = await axios.get(
                `${API_URL}/api/folders/app/${snippet}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              displayName = response.data.name;
              breadcrumbItem = (
                <Breadcrumb.Item key={url}>
                  <Link to={url}>{displayName}</Link>
                </Breadcrumb.Item>
              );
            } else if (pathSnippets[0] === "settings") {
              if (snippet === "emergency-access") {
                displayName = "Emergency Access";
                breadcrumbItem = (
                  <Breadcrumb.Item key={url}>
                    <Link to={url}>{displayName}</Link>
                  </Breadcrumb.Item>
                );
              } else if (snippet === "password") {
                displayName = "Password Change";
                breadcrumbItem = (
                  <Breadcrumb.Item key={url}>
                    <Link to={url}>{displayName}</Link>
                  </Breadcrumb.Item>
                );
              } else if (snippet === "Logs") {
                displayName = "Logs";
                breadcrumbItem = (
                  <Breadcrumb.Item key={url}>
                    <Link to={url}>{displayName}</Link>
                  </Breadcrumb.Item>
                );
              } else if (snippet === "payment") {
                displayName = "Payment";
                breadcrumbItem = (
                  <Breadcrumb.Item key={url}>
                    <Link to={url}>{displayName}</Link>
                  </Breadcrumb.Item>
                );
              } else {
                displayName = "Settings";
                breadcrumbItem = (
                  <Breadcrumb.Item key={url}>{displayName}</Breadcrumb.Item>
                );
              }
            } else {
              displayName = snippet
                .replace(/-/g, " ")
                .split(" ")
                .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                .join(" ");
              breadcrumbItem = (
                <Breadcrumb.Item key={url}>
                  <Link to={url}>{displayName}</Link>
                </Breadcrumb.Item>
              );
            }

            return breadcrumbItem;
          })
        );

        setBreadcrumbItems([
          !isDashboard && (
            <Breadcrumb.Item key="home">
              <Link to="/dashboard">Home</Link>
            </Breadcrumb.Item>
          ),
          ...items,
        ]);
      } catch (error) {
        console.error("Error fetching breadcrumb items:", error);
      }
    };

    fetchBreadcrumbItems();
  }, [location.pathname]);

  const logoutUser = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("Token mevcut değil");
        return;
      }

      const response = await axios.post(
        `${API_URL}/api/users/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem("token");
        navigate("/auth");
      }
    } catch (error) {
      console.error(
        "An error occurred during the logout process:",
        error.response ? error.response.data.error : error.message
      );
    }
  };

  const handleThemeChange = (checked) => {
    setIsDarkMode(checked);
    localStorage.setItem("isDarkMode", JSON.stringify(checked));
  };

  const sideMenu = [
    {
      key: "dashboard",
      icon: <DashboardOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    {
      key: "apps",
      icon: <SafetyOutlined />,
      label: <Link to="/apps">Apps</Link>,
    },
    {
      key: "shared items",
      icon: <ShareAltOutlined />,
      label: <Link to="/shared-items">Shared items</Link>,
    },
    {
      key: "secure-notes",
      icon: <FileProtectOutlined />,
      label: <Link to="/secure-notes">Secure Notes</Link>,
    },
    {
      key: "builder",
      icon: <EditOutlined />,
      label: <Link to="/builder">Builder</Link>,
    },
    {
      key: "user-management",
      icon: <UserOutlined />,
      label: <Link to="/user-management">User Management</Link>,
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: "Settings",
      children: [
        // {
        //   key: "8-1",
        //   label: (
        //     <HoverableLink
        //       to="/settings/logs"
        //       collapsed={collapsed}
        //       isDarkMode={isDarkMode}
        //     >
        //       Logs
        //     </HoverableLink>
        //   ),
        // },
        {
          key: "8-2",
          label: (
            <HoverableLink
              to="/settings/emergency-access"
              collapsed={collapsed}
              isDarkMode={isDarkMode}
            >
              Emergency Access
            </HoverableLink>
          ),
        },
        {
          key: "8-3",
          label: (
            <HoverableLink
              to="/settings/password"
              collapsed={collapsed}
              isDarkMode={isDarkMode}
            >
              Password
            </HoverableLink>
          ),
        },
        // {
        //   key: "8-4",
        //   label: (
        //     <HoverableLink
        //       to="/settings/payment"
        //       collapsed={collapsed}
        //       isDarkMode={isDarkMode}
        //     >
        //       Payment
        //     </HoverableLink>
        //   ),
        // },
      ],
    },
  ];

  const user = [
    {
      key: "1",
      label: <Link to="/profile">Profile</Link>,
    },
    {
      key: "2",
      label: (
        <span onClick={logoutUser} style={{ cursor: "pointer" }}>
          Log out
        </span>
      ),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: isDarkMode ? "#ffffff" : "#2C2C2C",
          colorTextBase: isDarkMode ? "#ffffff" : "#2C2C2C",
        },
        components: {
          Button: {
            colorPrimary: isDarkMode ? "#2C2C2C" : "#2C2C2C",
            colorText: isDarkMode ? "#ffffff" : "#2C2C2C",
            borderRadiusBase: "5px",
          },
          Switch: {
            colorPrimary: isDarkMode ? "#2C2C2C" : "#2C2C2C",
            colorText: isDarkMode ? "#ffffff" : "#2C2C2C",
            borderRadiusBase: "5px",
          },
          Checkbox: {
            colorPrimary: isDarkMode ? "#696969" : "#696969",
            colorText: isDarkMode ? "#ffffff" : "#696969",
            borderRadiusBase: "5px",
          },
          Radio: {
            colorPrimary: isDarkMode ? "#696969" : "#696969",
            colorText: isDarkMode ? "#ffffff" : "#696969",
            borderRadiusBase: "5px",
          },
        },
      }}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={210}
            collapsedWidth={80}
            className="sider"
            style={{
              height: collapsed ? "100%" : "100%",
              transition: "all 0.4s ease",
            }}
          >
            <Header className="header" style={{ width: collapsed ? 80 : 210 }}>
              <a href="/dashboard" className="demo-logo">
                <img
                  src={collapsed ? "/logo2.png" : "/logo2.png"}
                  className="logo"
                  alt="Logo"
                  style={{
                    width: collapsed ? "60px" : "55px",
                    transition: "width 0.4s ease",
                  }}
                />
                {!collapsed && (
                  <span className="Demo-logo-span">SecureNook</span>
                )}
              </a>
            </Header>
            <Menu
              theme={isDarkMode ? "dark" : "light"}
              mode="inline"
              defaultOpenKeys={["side1"]}
              className="sider-header"
              items={sideMenu}
            />
            <Footer
              className="sider-footer"
              style={{
                paddingLeft: "20px",
                marginTop: collapsed ? "-180px" : "-130px",
                flexDirection: collapsed ? "column" : "row",
                alignItems: collapsed ? "flex-start" : "center",
                justifyContent: collapsed ? "flex-start" : "space-between",
              }}
            >
              <Dropdown
                menu={{ items: user }}
                placement="topLeft"
                trigger={"click"}
              >
                <Button
                  size="small"
                  icon={<UserOutlined style={{ fontSize: 20 }} />}
                  className="Notification-btn"
                />
              </Dropdown>
              <Space direction={collapsed ? "vertical" : "horizontal"}>
                <Switch
                  checked={isDarkMode}
                  onChange={handleThemeChange}
                  checkedChildren="Koyu"
                  unCheckedChildren="Açık"
                  style={{
                    background: "#4B4B4B",
                    marginLeft: collapsed ? "-10px" : "0px",
                  }}
                />
                <img
                  src="/tr.png"
                  className="logo"
                  alt="Logo"
                  style={{ width: "20px" }}
                />
              </Space>
            </Footer>
          </Sider>

          <Layout
            style={{
              marginLeft: collapsed ? 80 : 210,
            }}
          >
            <div className="breadcrumb-main">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                className="breadcrumb-btn"
              />
              {!isDashboard && (
                <Breadcrumb className="ant-breadcrumb">
                  {breadcrumbItems}
                </Breadcrumb>
              )}
            </div>
            <Content
              style={{
                background: isDarkMode ? "#141414" : "#fff",
                borderRadius: borderRadiusLG,
              }}
              className="content"
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutMain;
