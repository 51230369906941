import React, { useState, useEffect } from "react";
import { Card, Descriptions, Typography, Spin } from "antd";
import axios from "axios";

const { Title } = Typography;

const ProfilePage = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${API_URL}/api/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const userEmail = localStorage.getItem("userEmail");
      const user = response.data.find((u) => u.email === userEmail);
      if (user) {
        setUserData(user);
      } else {
        console.error("Kullanıcı bulunamadı.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Kullanıcı bilgileri alınamadı:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div style={{ padding: "24px" }}>
      <Title level={1}>Profile</Title>
      {loading ? (
        <Spin tip="Yükleniyor..." />
      ) : (
        <Card
          style={{
            width: "100%",
            maxWidth: 500,
            borderRadius: 10,
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Descriptions
            column={{ xs: 1, sm: 1, md: 1, lg: 1 }}
            contentStyle={{ fontSize: "16px" }}
            labelStyle={{ fontWeight: "bold" }}
          >
            <Descriptions.Item label="Name">{userData.name}</Descriptions.Item>
            <Descriptions.Item label="Email">
              {userData.email}
            </Descriptions.Item>
            <Descriptions.Item label="Role">{userData.role}</Descriptions.Item>
          </Descriptions>
        </Card>
      )}
    </div>
  );
};

export default ProfilePage;
