import { Typography } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const Verify = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [verificationCode, setVerificationCode] = useState("");
  const [isVerify, setIsVerify] = useState(false); // `isVerify` state tanımlandı
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const handleVerify = async (e) => {
  e.preventDefault();

  try {
    const response = await fetch(`${API_URL}/api/users/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ verificationCode }),
    });

    if (response.ok) {
      //const data = await response.json();
      //localStorage.setItem("token", data.token); // Token'ı localStorage'a kaydet
      setSuccessMessage("Email verified successfully!");
      setErrorMessage(null);
      navigate("/dashboard");
    } else {
      const data = await response.json();
      setErrorMessage(data.error || "Verification failed.");
      setSuccessMessage(null);
    }
  } catch (error) {
    setErrorMessage("An error occurred. Please try again.");
    setSuccessMessage(null);
  }
};

  return (
    <div className="globalStyles">
      <div className={`container ${isVerify ? "change" : ""}`}>
        <div className="forms-container">
          <div className="form-control signin-form">
            <form onSubmit={handleVerify}>
              <div style={{ display: "flex", justifyContent: "center" }}></div>
              <Title level={2}>Verify Email</Title>
              <input
                type="text"
                placeholder="verification code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
              <button type="submit">Verify</button>
            </form>
            {errorMessage && (
              <p className="text-red-500 text-center mt-3">{errorMessage}</p>
            )}
            {successMessage && (
              <p className="text-green-500 text-center mt-3">
                {successMessage}
              </p>
            )}
          </div>
        </div>
        <div className="intros-container">
          <div className="intro-control signin-intro">
            <div className="intro-control__inner">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="/logo2.png"
                  alt="Logo"
                  width={120}
                  style={{
                    borderRadius: "10px",
                    marginBottom: "4px",
                  }}
                />
              </div>
              <p>Please verify using the code sent to your e-mail account.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
