import { useState, useEffect } from "react";
import { Form, Input, Button, message, Card, Typography } from "antd";
import axios from "axios";
import "./AuthForm.css";

const { Title } = Typography;

const ForgotPassword = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isForgot, setIsForgot] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      message.error("Authorization error. Please log in.");
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/api/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      message.error("An error occurred while retrieving user data.");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    const userExists = users.some((user) => user.email === values.email);

    if (!userExists) {
      message.error("The e-mail address was not found in the system.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/forgot-password`, {
        email: values.email,
      });

      if (response.status === 200) {
        message.success("A password reset email has been sent.");
        setEmailSent(true);
      }
    } catch (error) {
      message.error("An error has occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="globalStyles">
      <div className={`container ${isForgot ? "change" : ""}`}>
        <div className="forms-container">
          <div className="form-control signin-form">
            <Title level={2}>Forgot Your Password?</Title>
            <Form name="forgot-password" onFinish={onFinish} layout="vertical">
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address!",
                  },
                  { type: "email", message: "Enter a valid email!" },
                ]}
              >
                <Input placeholder="Your email address" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  {emailSent ? "Resend" : "Send"}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="intros-container">
          <div className="intro-control signin-intro">
            <div className="intro-control__inner">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="/logo2.png"
                  alt="Logo"
                  width={120}
                  style={{
                    borderRadius: "10px",
                    marginBottom: "4px",
                  }}
                />
              </div>
              <p>Please enter your e-mail to reset your password.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="forgot">
    //   <Card className="card-wrapper">
    //     <Title level={2}>Forgot Your Password?</Title>
    //     <Form name="forgot-password" onFinish={onFinish} layout="vertical">
    //       <Form.Item
    //         label="Email"
    //         name="email"
    //         rules={[
    //           { required: true, message: "Please enter your email address!" },
    //           { type: "email", message: "Enter a valid email!" },
    //         ]}
    //       >
    //         <Input placeholder="Your email address" />
    //       </Form.Item>
    //       <Form.Item>
    //         <Button type="primary" htmlType="submit" loading={loading} block>
    //           {emailSent ? "Resend" : "Send"}
    //         </Button>
    //       </Form.Item>
    //     </Form>
    //   </Card>
    // </div>
  );
};

export default ForgotPassword;
